<template>
    <div class="mint__closing__alert__container">
        <div v-if="isFreeMint" class="mint__closing__alert__container__content">
            <span class="mint__closed"> The Big Bang mint has closed permanently.</span>
            <router-link to="/big-bang-next-steps">What's next? </router-link>
        </div>
        <div v-else class="mint__closing__alert__container__content">
            <span> The Big Bang mint will close permanently:</span>
            <em class="time">Thurs, Jan 27 at 5pm ET / 10pm UTC</em> 
            <router-link to="/big-bang-next-steps">What's next? </router-link>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {
        isFreeMint() {
            return process.env.VUE_APP_IS_FREE_MINT == 'true'
        }
    }
}
</script>

<style lang="scss" scoped>
.mint__closing__alert__container {
    background-color: var(--color-yellow);
    padding: 4px;
    width: 100%;
    position: sticky;
    z-index: 1;
    top:74px;
    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        @include respond-to("small and down") { 
            flex-direction: column;
            row-gap: 4px;
        }
    }
    span {
        line-height: 24px;
        font-weight: 500;
        font-size: 16px;
        &.mint__closed {
            font-weight: 600;
        }
    }
    a {
        color: var(--color-neutral-black);
        line-height: 18px;
    }
    em {
        line-height: 24px;
        font-weight: 700;
        font-size: 16px;
    }
}
</style>
