import {
    getAddress,
    viewingKeyManager,
    keplrViewingKeyManager,
    enablePermit,
    createContract
} from "@stakeordie/griptape.js";
import { snip721Def, mintingDef, studiesDef } from "@/contracts/definitions.js";
import api from "@/service/api";

export function getQuery(permit, query) {
    const usePermit = process.env.VUE_APP_USE_PERMITS == "true";
    if (usePermit) {
        return { with_permit: { query, permit } };
    } else {
        return query;
    }
}

export async function isUserAuthenticated(contract) {
    const usePermit = process.env.VUE_APP_USE_PERMITS;

    const { permit, key } = await getContext(contract.at);
    if (permit && usePermit == "true") {
        return true;
    } else if (key && usePermit == "false") {
        return true;
    } else {
        return false;
    }
}

export async function authenticateUser(
    contract,
    permissions,
    idContractType = 20
) {
    const usePermit = process.env.VUE_APP_USE_PERMITS == "true";
    const { permit, key } = await getContext(contract.at);

    const res = await contract.createViewingKey();
    const parsed = res.parse();

    if (!permit && usePermit) {
        await enablePermit(contract, permissions);
    } else if (!key && !usePermit) {
        if (idContractType == 20) {
            await keplrViewingKeyManager.add(contract);
        } else {
            viewingKeyManager.add(contract, parsed.viewing_key.key);
        }
    }
}

export function getAuthMethod() {
    const isPermit = process.env.VUE_APP_USE_PERMITS == "true";
    if (isPermit) {
        return "permit";
    } else {
        return "viewing key";
    }
}

async function getContext(contractAddress) {
    // Get all context variables.
    const address = getAddress();
    const key = viewingKeyManager.get(contractAddress);

    let permit;
    const rawPermit = localStorage.getItem(
        `query_permit_${address + contractAddress}`
    );
    if (rawPermit) {
        permit = JSON.parse(rawPermit);
    }

    // Set the context.
    return { key, address, permit };
}

export function currentDate() {
    var today = new Date();
    var currentDate = String(
        today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate()
    );

    return currentDate;
}

export function fromBase64(str) {
    return decodeURIComponent(escape(window.atob(str)));
}

export function formatTraitAttribute(str) {
    let result = str.split(" ").map((item, index) => {
        return [...item]
            .map((letter, i) => {
                return i == 0 ? letter.toUpperCase() : letter;
            })
            .join("");
    });
    return (
        result
            .join("")
            .charAt(0)
            .toLowerCase() + result.join("").slice(1)
    );
}

export function stringIdToNumber(stringId) {
    return parseInt(stringId) % 1000000;
}

export function getCurrentProjectId() {
    const projectId = process.env.VUE_APP_CURRENT_PROJECT_ID;
    return projectId;
}

export async function initContracts() {
    const projects = await api.projects.getAll();
    return projects.forEach(project => {
        const id = project.title.toLowerCase().replaceAll(" ", "-");
        const snip721Id = `${id}-snip721-contract`;
        const mintingId = `${id}-minting-contract`;
        const studiesId = `${id}-studies-contract`;
        createContract({
            id: snip721Id,
            at: project.snip721ContractAddress,
            definition: snip721Def
        });
        createContract({
            id: mintingId,
            at: project.mintingContractAddress,
            definition: mintingDef
        });
        createContract({
            id: studiesId,
            at: process.env.VUE_APP_STUDIES_CONTRACT,
            definition: studiesDef
        });
    });
}
