import Vue from 'vue'
import App from './App.vue'
import router from './router'

import DefaultLayout from './layouts/DefaultLayout';

import Flare from '@lkmx/flare';

import './filters/time-till';
import { getKeplrAccountProvider, gripApp ,onAccountChange} from '@stakeordie/griptape.js';

Vue.config.productionTip = false

Vue.use(Flare);

const restUrl = process.env.VUE_APP_NODE_URL;
const provider = getKeplrAccountProvider();

const balanceState = {
  sscrtBalance: ""
}

gripApp(restUrl, provider, () => {
    new Vue({
        router,
        render: h => h(App),
        data: balanceState
    }).$mount('#app');
});

onAccountChange(() => {
  window.location.reload();
});
Vue.component('DefaultLayout', DefaultLayout);
