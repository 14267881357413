import {
    createContract,
    extendContract,
    snip20Def
} from "@stakeordie/griptape.js";

const def = {
    messages:{
        send({ padding }, recipient, amount, msg) {
            var handleMsg = {
                send: { recipient, amount, msg, padding},
            };
            const fees = 600_000;
            return { handleMsg, fees };
        },
        createViewingKey({ entropy }) {
            const handleMsg = {
                create_viewing_key: { entropy }
            };
            const fees = 150_000;
            return { handleMsg, fees };
        },
        deposit(_, amount) {
            const handleMsg = { deposit: {} };
            const transferAmount = { denom: 'uscrt', amount };
            const fees = 860_000;
            return { handleMsg, transferAmount, fees }
        },
        redeem(_, amount) {
            const fees = 150_000;
            const handleMsg = { redeem: { amount } };
            return { handleMsg , fees}
        },

    },
    queries : {
        getBalance({ key, address }){
            const query = { balance: { address, key } };
            return query;
        }
    }
}

export const sscrt = createContract({
    id: 'sscrt',
    at: process.env.VUE_APP_CONTRACT_SSCRT_ADDRESS,
    definition: extendContract(snip20Def,def)
});
