import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_URL;

const manageCallback = function(promise) {
    return new Promise((resolve, reject) => {
        promise
            .then(response => resolve(response.data))
            .catch(error => {
                reject(error.response ? error.response.data : error);
            });
    });
};

const client = {
    plain: {
      get: function(path, config) {
        return manageCallback(axios.get(path, config));
      },
      put: function(path, data, config) {
        return manageCallback(axios.put(path, data, config));
      },
      post: function(path, data, config) {
        return manageCallback(axios.post(path, data, config));
      },
      delete: function(path, config) {
        return manageCallback(axios.delete(path, config));
      },
      fileUpload: function(path, data, config) {
        config.cancelToken = config.cancelToken || axios.CancelToken.source().token;
        return manageCallback(axios.post(path, data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            ...config
          })
        );
      },
      secure: {
        get: function(path, config) {
          return client.plain.get(path, config);
        },
        put: function(path, data, config) {
          return client.plain.put(path, data, config);
        },
        post: function(path, data, config) {
          return client.plain.post(path, data, config);
        },
        delete: function(path, config) {
          return client.plain.delete(path, config);
        },
        fileUpload: function(path, data, config) {
          return client.plain.fileUpload(path, data, config);
        }
      },
    },
    get: function(path, config) {
      return client.plain.get(apiUrl + path, config);
    },
    put: function(path, data, config) {
      return client.plain.put(apiUrl + path, data, config);
    },
    post: function(path, data, config) {
      return client.plain.post(apiUrl + path, data, config);
    },
    delete: function(path, config) {
      return client.plain.delete(apiUrl + path, config);
    },
    fileUpload: function(path, data, config) {
      client.plain.fileUpload(apiUrl + path, data, config);
    },
    secure: {
      get: function(path, config) {
        return client.get(path, config);
      },
      put: function(path, data, config) {
        return client.put(path, data, config);
      },
      post: function(path, data, config) {
        return client.post(path, data, config);
      },
      delete: function(path, config) {
        return client.delete(path, config);
      },
      fileUpload: function(path, data, config) {
        return client.fileUpload(path, data, config);
      }
    },
};
  
export default client;  