<template>
    <div class="error__message__content">
        <span>{{errorMessage}}</span>
    </div>
</template>

<script>
export default {
    name: 'ErrorMessage',
    props: {
        errorMessage: { type: String, required: true }
    }
}
</script>

<style lang="scss" scoped>
.error__message__content {
    width: 100%;
    background: var(--color-starter-red-02);
    padding: 7px 10px;
    span {
        color: var(--color-neutral-white);
        line-height: 24px;
    }
}
</style>
