import Vue from 'vue';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

Vue.filter('long-date', (value) => {
    if (!value) {
        return '--';
    }

    const date = new Date(value.replace(/-/g, '\/'));

    return months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear(); 
});