import client from './client.js';
import axios from 'axios';

const apiDirection = process.env.VUE_APP_API_URL;

const downloadBlob = async function (url) {
    const response = await axios({
        url,
        method: "GET",
        responseType: "blob",
    });
    return window.URL.createObjectURL(
        new Blob([response.data])
    );
};

const api = {
    projects: {
        get(id) {
            return client.get(`/projects/${id}`);
        },
        getPreview(id) {
            return downloadBlob(`${apiDirection}/projects/${id}/preview`);
        },
        getAll() {
            return client.get(`/projects`);
        },
        arts: {
            search(projectId, searchTerm = '', size, page) {
                return client.get(
                    `/projects/${projectId}/arts?size=${size}&searchTerm=${searchTerm}&page=${page}`
                );
            },
            getEmprops(projectId, ids = '') {
                return client.get(`/projects/${projectId}/em-props?ids=${ids}`);
            },
            getRandomImage(projectId) {
                return client.get(`/projects/${projectId}/random-art`);
            },
            get(projectId, artId) {
                return client.get(`/projects/${projectId}/arts/${artId}`);
            },
            getPreview(projectId, artId) {
                return downloadBlob(`${apiDirection}/projects/${projectId}/arts/${artId}/preview`);
            },
            getThumbnail(projectId, artId) {
                return downloadBlob(`${apiDirection}/projects/${projectId}/arts/${artId}/thumbnail`);
            },
            getGenerated(projectId, size) {
                return client.get(`/projects/${projectId}/arts?size=${size}`);
            },
            reserve(projectId, command) {
                return client.post(`/projects/${projectId}/arts`, command);
            },
            generateArt(projectId, artId, command) {
              return client.post(`/projects/${projectId}/arts/${artId}`, command);
            },
            getAllArtsByProject(projectId, size) {
                return client.get(`/projects/${projectId}/arts?size=${size}`);
            },
            enableGeneration(projectId, artId, command) {
                return client.post(`/projects/${projectId}/arts/${artId}/generation`, command);
            },
            traits:{
                saveTraits(projectId, artId, command) {
                  return client.post(`/projects/${projectId}/arts/${artId}/traits`, command);
                },
                getArtTraits(projectId, artId) {
                  return client.get(`/projects/${projectId}/arts/${artId}/traits`,);
                },
                getTraitsFilter(projectId, attribute, attributeValue, totalTokens) {
                    return client.get(`/projects/${projectId}/arts?${attribute}=${attributeValue}&size=${totalTokens}`);
                },
                rarity:{
                    get(projectId, artId) {
                      return client.get(`/projects/${projectId}/arts/${artId}/traits/rarity`,);
                    },  
                }
            },
        },
        studies: {
            get(projectId, studyID) {
                return client.get(`/projects/${projectId}/studies/${studyID}`);
            },
            getAll(projectId) {
                return client.get(`/projects/${projectId}/studies/`);
            },
            getStudiesByIds(projectId, ids) {
                return client.get(`/projects/${projectId}/studies?ids=${ids}`);
            },
        },
        tests: {
            getAll(projectId, size) {
                return client.get(`/projects/${projectId}/arts/tests?size=${size}`);
            },
            get(projectId, testId) {
                return client.get(`/projects/${projectId}/arts/tests/${testId}`);
            },
        }
    },
}

export default api;
