import {
    snip721Def as snip721Griptape,
    extendContract,
} from "@stakeordie/griptape.js";
import { getQuery } from "@/utils.js";

const emprops721 = {
    messages: {
        createViewingKey({ entropy }) {
            const handleMsg = {
                create_viewing_key: { entropy }
            };
            const fees = 250_000;
            return { handleMsg, fees };
        },
        mintNft({ padding, address }) {
            const fees = 250_000;
            return {
                handleMsg: {
                    mint_nft: {
                        padding, owner: address
                    }
                },
                fees
            }
        },
        answerQuestionnare(
            { address, key }, artiId, answers
        ) {
            const handleMsg = {
                answer_question: {
                    token_id: artiId, //art ID
                    viewer: {
                        address: address, // Wallet Address
                        viewing_key: key // Viewing Key Contract
                    },
                    answers
                }
            };
            const fees = 170_000;
            return { handleMsg, fees }
        },
        addProvenance({ address, key }, token_id, owner_name, acquisition_date, token_price, fees) {
            //each provenance adds around 6k to the fees
            const handleMsg = {
                add_provenance: {
                    token_id,
                    viewer: {
                        address,
                        viewing_key: key
                    },
                    name: owner_name,
                    acquisition_date,
                    price: token_price
                }
            }
            return { handleMsg, fees }
        },
        generateArt(ctx, token_id) {
            const fees = 175_000;
            const handleMsg = {
                generate_art: {
                    token_id
                }
            };
            return { handleMsg, fees };
        },
        setMetadata({ padding }, token_id, public_metadata) {
            const fees = 250_000;
            const handleMsg = {
                set_metadata: {
                    token_id,
                    public_metadata,
                    padding
                }
            };
            return { handleMsg, fees };
        },
        setWhiteListedApproval({ padding }, address, token_id, view_owner) {
            const fees = 400_000;
            const handleMsg = {

                set_whitelisted_approval: {
                    address,
                    token_id,
                    view_owner,
                    padding,
                },
            };
            return { handleMsg, fees };
        },
    },
    queries: {
        getNftDossier(
            { address, key: viewing_key, permit },
            token_id,
            include_expired,
            onlyPublicData = false
        ) {
            if (onlyPublicData) {
                return { nft_dossier: { token_id } };
            }
            const viewer = { viewing_key, address };
            const query = {
                nft_dossier: {
                    token_id,
                    viewer,
                    include_expired,
                },
            };
            return getQuery(permit, query);
        },
        contractInfo() {
            return {
                contract_info: {},
            };
        },
        getTokens({ address, key, permit }) {
            const query = {
                tokens: {
                    owner: address,
                    viewer: address,
                    viewing_key: key,
                },
            }
            return getQuery(permit, query);
        },
        getAllTokens({ address, key: viewing_key, permit }) {
            const query = {
                all_tokens: {
                    viewer: {
                        address,
                        viewing_key
                    }
                }
            };
            return getQuery(permit, query)
        },
        getP5jsCode() {
            return {
                code: {}
            };
        },
        getQuestions() {
            return {
                questionnaire: {},
            };
        },
        getHasAnswered({ address, key, permit }, token_id) {
            const query = {
                has_answered: {
                    token_id,
                    viewer: {
                        address,
                        viewing_key: key
                    }
                }
            }

            return getQuery(permit, query);
        },
        getIsGenerated({ address, key, permit }, token_id) {
            const query = {
                is_generated: {
                    token_id,
                    viewer: {
                        address,
                        viewing_key: key
                    }
                }
            }

            return getQuery(permit, query);
        },
        getOwnerOf({ address, key, permit }, token_id) {
            const query = {
                owner_of: {
                    token_id,
                    viewer: {
                        address,
                        viewing_key: key
                    }
                }
            }
            return getQuery(permit, query);
        },
        getProvenance({ address, key, permit }, token_id) {
            const query = {
                provenance: {
                    token_id,
                    viewer: {
                        address,
                        viewing_key: key
                    }
                }
            }
            return getQuery(permit, query);
        },
        getInventoryApprovals({ address, key, permit }) {
            const query = {
                inventory_approvals: {
                    address: address,
                    viewing_key: key,
                    include_expired: false
                }
            }
            return getQuery(permit, query)
        },
    },
};
export const snip721Def = extendContract(snip721Griptape, emprops721);

export const empropsMintDef = {
    messages: {},
    queries: {
        getIsPremint() {
            return { premint: {} }
        },
        approvedStudies() {
            return { studies_list: {} }
        },
        getDutchAuctionInfo(_, time) {
            return { dutch_auction_info: { time: time } }
        }
    }
};

export const mintingDef = extendContract(snip721Griptape, empropsMintDef);

export const empropsStudyDef = {
    messages: {
        createViewingKey({ entropy, padding }) {
            const handleMsg = {
                create_viewing_key: { entropy, padding }
            };
            const fees = 150_000;
            return { handleMsg, fees };
        },
    },
    queries: {
        getTokens({ address, key, permit }) {
            const query = {
                tokens: {
                    owner: address,
                    viewer: address,
                    viewing_key: key,
                    limit: 256
                },
            }
            return getQuery(permit, query);
        },
        getNftDossier(
            { address, key: viewing_key, permit },
            token_id,
            include_expired,
            onlyPublicData = false
        ) {
            if (onlyPublicData) {
                return { nft_dossier: { token_id } };
            }
            const viewer = { viewing_key, address };
            const query = {
                nft_dossier: {
                    token_id,
                    viewer,
                    include_expired,
                },
            };
            return getQuery(permit, query);
        },
        getInventoryApprovals({ address, key, permit }) {
            const query = {
                inventory_approvals: {
                    address: address,
                    viewing_key: key,
                    include_expired: false
                }
            }
            return getQuery(permit, query)
        },
        getAllTokens({ address, key: viewing_key, permit }) {
            const query = {
                all_tokens: {
                    viewer: {
                        address,
                        viewing_key
                    }
                }
            };
            return getQuery(permit, query)
        },
    }
};

export const studiesDef = extendContract(snip721Griptape, emprops721);
