<template>
    <div class="modal">
        <button v-show="label" :class="buttonClasses" @click="showModal">{{ label }}</button>
        <div :id="`modal-wrapper-${id}`" class="modal__wrapper" v-show="modalIsVisible">
            <div class="modal__overlay" @click="closeOnOutside && hideModal()"></div>
            <div class="modal">
                <div class="modal__content" :style="{'width': `${width}`, 'padding': `${padding}`}"
                     :class="{'modal__content--bottom-full-width': fullView}">
                    <button class="btn btn--close" v-if="closable" @click="hideModal" id="hide-modal">
                        <img src="@/assets/icons/close.svg" alt="Close Button for Warning Modal">
                    </button>
                    <div class="modal__body">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "modal",
    props: {
        id: String,
        label: String,
        buttonClasses: String,
        closeOn: String,
        openOn: String,
        closeOnOutside: {type: Boolean, default: true},
        closable: {type: Boolean, default: true},
        width: String,
        fullView: Boolean,
        padding: String
    },
    data() {
        return {
            modalIsVisible: false
        };
    },
    mounted() {
        window.addEventListener('popstate', () => {
            this.hideModal();
        });
        if (this.closeOn) {
            this.$root.$on(this.closeOn, () => {
                this.hideModal();
            });
        }

        if (this.openOn) {
            this.$root.$on(this.openOn, () => {
                this.showModal();
            });
        }
    },
    beforeDestroy() {
        this.hideModal();
    },
    methods: {
        showModal() {
            this.modalIsVisible = true;
        },
        hideModal() {
            this.modalIsVisible = false;
        }
    }
}

</script>

<style lang="scss">
.modal {

    .btn {
        cursor: pointer;

        &--close {
            i {
                display: block;
                width: 16px;
                height: 16px;

            }

            position: absolute;
            top: 28px;
            right: 28px;
            border: none;
            font-size: rem(16px);
            background-color: transparent;

            &:focus {
                outline: none;
            }
        }
    }

    &--dialog {
        width: 100%;
        height: 470px;
        padding: 55px;
        background-color: white;

        h1 {
            text-transform: uppercase;
            font-size: 34px;
            text-align: center;
            font-weight: bold;
            padding: 13px 0;
            line-height: 40px;
        }

        p {
            font-size: 16px;
            margin: 21px 0;
            text-align: center;
        }

        button {
            font-weight: bold;
            height: 52px;
            margin: 20px 0;
            display: block;
            width: 100%;
            border: 3px solid #ff4b00;
            text-transform: uppercase;

            &:nth-child(n) {
                color: #ffffff;
                background-color: #ff4b00;
            }

            &:nth-child(2n) {
                color: #ff4b00;
                background-color: white;
            }
        }
    }

    &__wrapper {
        position: fixed;
        position: device-fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 300;
        transition: all 0.5 ease;
    }

    &__overlay {
        position: fixed;
        position: device-fixed;
        background-color: rgba(128, 128, 128, 0.8);
        width: 100%;
        height: 100%;
        left: 0;
    }

    &__content {
        position: absolute;
        width: 343px;
        background-color: white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);

        &--bottom-full-width {
            transform: unset;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100% !important;
        }

        &__header {
            text-align: center;
            font-size: 2rem;
            font-weight: 900;

            .title {
                padding: 2.175rem;
                display: inline-block;
            }
        }

        &__body {
            text-align: center;
            padding: 1rem 3rem;
            height: 100%;

            p {
                margin-bottom: 2.175rem;
            }
        }
    }

    .normalcase {
        text-transform: none;
    }
}

.modal__wrapper {
    display: inherit !important;
    transition: all 0.2s linear;

    .modal__body {
        height: 100%;
    }

    &[style*="display: none;"] {
        opacity: 0;
        pointer-events: none;
        user-select: none;
    }
}

</style>
