<template>
  <simple-layout class="default-layout">
    <template #header>
      <column class="default-layout__header-column" mode="full" v-if="!currentRoute('live')">
        <block class="default-layout__header-box">
          <div class="default-layout__wrapper">
            <nav class="default-layout__logo default-layout__logo-header">
              <img
                @click="$router.push({name: 'home'})"
                src="@/assets/icons/logo.svg" 
                alt="logo"
              >
              <navigation-links class="default-layout__links default-layout__links--header"></navigation-links>
            </nav>

            <img class="default-layout__menu-icon" src="@/assets/icons/icon-menu.svg" alt="Menu Icon" @click="toggleMenu">

            
            <div class="header-right">

                <div class="navigation-links">
                    <router-link :to="{name: 'faq'}">FAQ</router-link>   
                    <router-link :to="{name: 'about-emprops'}">About EmProps</router-link> 
                    <router-link v-show="isAddressAvailable" :to="{name: 'myemprops', params: { projectId: currentProjectId }}">My EmProps</router-link>
                </div>

                <button
                    class="swapper-btn"
                    @click="openSwapperModal"
                    v-show="isAddressAvailable"
                >
                    <img src="@/assets/icons/refresh.svg" alt="refresh icon"> 
                    <span>Swapper</span>
                </button>

                <div class="wallet">
                    <button v-show="isAddressAvailable">
                        <img src="@/assets/icons/wallet.svg" alt="Wallet Icon"/>
                        <span> 
                        <strong>{{ address }} <em>|</em> </strong>
                        <span v-if="balanceIsLoading">Loading...</span>
                        <span v-else-if="sscrtAuth && $root.sscrtBalance"> {{$root.sscrtBalance}} sSCRT</span> 
                        <span v-else @click="createSscrtAuth" class="show-balance"> 
                            Show balance
                        </span> 
                        </span>
                    </button>
                    <button v-show="!isAddressAvailable" @click="connectToWallet">
                        <img src="@/assets/icons/wallet.svg" alt="Wallet Icon"/>
                        <span>CONNECT TO KEPLR</span>
                    </button>
                </div>
            </div>
            
          </div>

          <div class="default-layout__menu" v-show="isOpen">
            <nav class="default-layout__nav">
              <navigation-links class="default-layout__links default-layout__links--menu" @close="closeMenu"></navigation-links>
            </nav>
          </div>

        </block>
      </column>
    </template>

    <main>
        <router-view />
        <!-- <mint-closing-alert /> -->

        <modal
            class="modal"  
            ref="swapperModal"
            :closable="false" 
            :close-on-outside="false"
            id="swapperModal"
        >
        <div class="modal__content">
            <div class="modal__content__header">
                <h3>Swapper</h3>
                <img
                    @click="closeSwapperModal" 
                    src="@/assets/icons/close.svg" alt="close"
                >
            </div>

            <error-message 
                v-if="hasError"
                :error-message="errorMessage"
            >
            </error-message>

            <div class="modal__content__form from">
                <div class="modal__content__form__title">
                    <div class="modal__content__form__title__left">
                        <span>From: </span>
                        <h2>{{ swapFromNative ? 'SCRT' : 'sCRT'}}</h2>
                    </div>
                    <div class="modal__content__form__title__right">
                        <span>Balance: </span>
                        <strong v-if="!sscrtAuth && !swapFromNative" @click="createSscrtAuth" class="no__auth">
                            View balance
                        </strong>
                        <strong>
                            {{ swapFromNative ? `${scrtBalance}` : `${$root.sscrtBalance}`}} 
                            {{ swapFromNative ? 'SCRT' : 'sCRT'}}
                        </strong>
                    </div>
                </div>
                <input 
                    type="number"
                    placeholder="0.00"
                    v-model="from"
                    :min="0"
                    :class="{form__error: !hasFounds }"
                >
                <span
                    class="form__error__message" 
                    v-if="!hasFounds"
                >
                    Insufficient Funds
                </span>
            </div>

            <div class="modal__content__invert" @click="switchSwapTokens">
                <img src="@/assets/icons/refresh-black.svg" alt="revert">
            </div>

            <div class="modal__content__form from">
                <div class="modal__content__form__title">
                    <div class="modal__content__form__title__left">
                        <span>To: </span>
                        <h2>{{ swapFromNative ? 'sSCRT' : 'SCRT'}}</h2>
                    </div>
                    <div class="modal__content__form__title__right">
                        <span>Balance: </span>
                        <strong v-if="!sscrtAuth && swapFromNative" @click="createSscrtAuth" class="no__auth">
                            View balance
                        </strong>
                        <strong else>
                            {{ swapFromNative ? `${$root.sscrtBalance}` : `${scrtBalance}`}} 
                            {{ swapFromNative ? 'sSCRT' : 'SCRT'}}
                        </strong>
                    </div>
                </div>
                <input 
                    type="number"
                    placeholder="0.00"
                    :value="from"
                    :readonly="true"
                >
            </div>

            <button
                @click="validateForm(from)" 
                :class="{loading: swapIsLoading }"
                :disabled="disableSwapButton"
            >
                <slot v-if="swapIsLoading"> <img src="@/assets/icons/spinner.svg"></slot>
                <slot v-else> Swap </slot>
            </button>
        </div>
        </modal>
    
    </main>

    <template #footer>
      <column 
        class="default-layout__footer"
        number="2"
        number-m="1"
        number-s="1"
        v-if="!currentRoute('live')"
        :class="{'default-layout__resilient': $route.params.projectId == 2 }"
    >
        <block class="default-layout__logo-wrapper">
          <div class="default-layout__logo-wrapper__container">
            <img class="default-layout__logo" 
              @click="$router.push({name: 'home'})"
              src="@/assets/icons/footer-logo.svg" alt="logo-footer"
            >
          </div>
        </block>
        <block class="default-layout__footer__nav-wrapper">
          <nav class="default-layout__footer__nav">
            <div class="default-layout__footer__nav__links">
              <a @click="$router.push({name: 'home'})">Home</a>  
              <a @click="$router.push({name: 'projects'})">Projects</a>
              <a @click="$router.push({name: 'faq'})">FAQ</a>
              <a @click="$router.push({name: 'about-emprops'})">About EmProps</a>
            </div>
            <div class="default-layout__footer__nav__social">
                <a href="https://twitter.com/emprops_io" target="_blank">
                  <img src="@/assets/icons/logo-twitter.svg" alt="twitter">
                </a>
                <a href="https://discord.gg/7k2dNMUAe3" target="_blank">
                  <img src="@/assets/icons/logo-discord.svg" alt="discord">
                </a>
                <a href="https://www.instagram.com/emprops_io" target="_blank">
                  <img src="@/assets/icons/logo-instagram.svg" alt="instagram">
                </a>
            </div>
          </nav>
        </block>
      </column>
    </template>
  </simple-layout>
</template>

<script>
import { 
  bech32, 
  getAddress, 
  bootstrap, 
  onAccountAvailable, 
  keplrViewingKeyManager,
  viewingKeyManager,
  coinConvert,
  getNativeCoinBalance,
  getKeplr,
  getChainId
} from '@stakeordie/griptape.js';
import { sscrt } from '@/contracts/sscrt';
import { getCurrentProjectId } from "@/utils.js";
import NavigationLinks from "@/components/NavigationLinks";
import Modal from '@/components/Modal';
import ErrorMessage from '@/components/utils/ErrorMessage';
import MintClosingAlert from '../components/UiUtils/Alerts/MintClosingBanner.vue';

export default {
  components: { NavigationLinks, Modal, ErrorMessage, MintClosingAlert},
  data() {
    return {
      isOpen: false,
      address: '',
      isKeplrAccountAvailable: false,
      sscrtAuth: null,
      //Swapper Modal
      balanceIsLoading: false,
      scrtBalance: null,
      from: null,
      to: null,
      swapIsLoading: false,
      hasError: false,
      errorMessage: null,
      swapFromNative: true,
      hasFounds: true,
      currentProjectId: getCurrentProjectId()
    }
  },
  computed: {
    isAddressAvailable() {
      return this.isKeplrAccountAvailable && this.address;
    },
    disableSwapButton() {
        return this.from <= 0;
    }
  },
  mounted() {
    onAccountAvailable(async () => {
      this.address = bech32(getAddress(), 16);
      this.isKeplrAccountAvailable = true;
      await this.isSscrtAuth();
      await this.getScrtBalance();
    });
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    closeMenu() {
      this.isOpen = false;
    },
    async connectToWallet() {
        if(process.env.VUE_APP_NODE_URL == 'https://api.pulsar.griptapejs.com'){
            await this.suggestExperimentalChain()
        }
      bootstrap();
    },
    async suggestExperimentalChain(){
         const keplr = await getKeplr();
        if (!keplr) throw new Error('No Keplr');
        
        const chainId = await getChainId()
        await keplr.experimentalSuggestChain({
            chainId: chainId,
            chainName: chainId,
            rpc: 'https://rpc.pulsar.griptapejs.com',
            rest: 'https://api.pulsar.griptapejs.com',
            bip44: {
            coinType: 529,
            },
            coinType: 529,
            stakeCurrency: {
            coinDenom: 'SCRT',
            coinMinimalDenom: 'uscrt',
            coinDecimals: 6,
            },
            bech32Config: {
            bech32PrefixAccAddr: 'secret',
            bech32PrefixAccPub: 'secretpub',
            bech32PrefixValAddr: 'secretvaloper',
            bech32PrefixValPub: 'secretvaloperpub',
            bech32PrefixConsAddr: 'secretvalcons',
            bech32PrefixConsPub: 'secretvalconspub',
            },
            currencies: [
            {
                coinDenom: 'SCRT',
                coinMinimalDenom: 'uscrt',
                coinDecimals: 6,
            },
            ],
            feeCurrencies: [
            {
                coinDenom: 'SCRT',
                coinMinimalDenom: 'uscrt',
                coinDecimals: 6,
            },
            ],
            gasPriceStep: {
            low: 0.1,
            average: 0.25,
            high: 0.4,
            },
            features: ['secretwasm'],
        });
    },
    currentRoute(path) {
      return this.$route.path.includes(path);
    },
    async isSscrtAuth() {
        this.sscrtAuth = keplrViewingKeyManager.get(sscrt.at);
        if (!this.sscrtAuth) return;
        await this.getSscrtBalance();
    },
    async createSscrtAuth() {
      this.balanceIsLoading = true;
      try {
        await keplrViewingKeyManager.add(sscrt);
        this.sscrtAuth = keplrViewingKeyManager.get(sscrt.at);
        if (this.sscrtAuth) {
          viewingKeyManager.set(sscrt.at, this.sscrtAuth);
        } 
        await this.isSscrtAuth();
      }catch(e){
        console.log('Error: ', e)
      }finally {
        this.balanceIsLoading = false;
      }
    },
    async getSscrtBalance() {
      this.balanceIsLoading = true;
      try {  
        const key = viewingKeyManager.get(sscrt.at);

        if(!key) return;

        const res = await sscrt.getBalance();

        const balance = coinConvert(res.balance.amount, '6', 'human')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        this.$root.sscrtBalance = balance;
      }catch(error) {
        console.error(error);
      }finally {
        this.balanceIsLoading = false;
      }
    },
    async getScrtBalance() {
        const balance = await getNativeCoinBalance();
        this.scrtBalance = coinConvert(balance, 6, 'human', 2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    openSwapperModal() {
        this.$refs.swapperModal.showModal();
    },
    closeSwapperModal() {
        this.$refs.swapperModal.hideModal();
    },
    async validateForm(amountToSwap) {
        let balance = 0;

        let amountToSwapToNumber = parseFloat(amountToSwap)
        let scrtBalanceToNumber = parseFloat(this.scrtBalance);
        let sScrtBalanceToNumber = parseFloat(this.$root.sscrtBalance);

        if(this.swapFromNative) {
            balance = scrtBalanceToNumber;
        } else {
            balance = sScrtBalanceToNumber;
        }

        if( amountToSwapToNumber > balance ) {
            this.hasFounds = false;
            setTimeout(() => {
                this.hasFounds = true;
            }, 3000);
        } else {
            await this.swapTokens(amountToSwap);
        }
    },
    async swapTokens(amount) {
        try {
            this.swapIsLoading = true;
            const amount_convert = coinConvert(amount, 6, 'machine');
            if(this.swapFromNative){
                await sscrt.deposit(amount_convert);
            }else {
                await sscrt.redeem(amount_convert);
            }
            await this.getSscrtBalance();
            await this.getScrtBalance();
        }catch(error){
            this.hasError = true;
            this.errorMessage = 'An error ocurred, please try again';
            setTimeout(() => {
                this.hasError = false;
                this.errorMessage = null;
            }, 5000);
        }finally {
            this.swapIsLoading = false;
            this.from = null;
        }
    },
    switchSwapTokens() {
        this.swapFromNative = !this.swapFromNative;
    },
  }
}
</script>

<style lang="scss" scoped>

@include respond-to("medium and down") {
    .default-layout__menu {
        height: calc(100vh) !important;
        top: 44px !important;
        background-color: var(--color-neutral-white) !important;
    }
    .default-layout__menu-icon {
        background-color: var(--color-neutral-black);
        border-radius: 100%;
        padding: 4px;
    }
}

.default-layout {
  &__header-column {
    position: sticky;
    top: 0;
    background-color: var(--color-neutral-white);
    z-index: 1;
    color: var(--color-neutral-black);
    padding-left: 16px;
    height: 44px;
    display: flex;
    align-items: center;
  }
  &__header-box {
    --f-block-default-padding: 0px 16px 0px 0px; 
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    nav {
      display: flex;
      align-items: center;
    }
    .header-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 24px;

        .wallet {
            button {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;
                margin: 0;
                padding: 8px;
            }
        }

        .navigation-links {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 24px;
            a {
                text-decoration: none;
                color: var(--color-neutral-black);
                line-height: 24px;
                font-weight: 700;
            }
            span {
                line-height: 24px;
                font-weight: 700;
                cursor: pointer;
            }
            &-swapper {
                display: flex;
                align-items: center;
                column-gap: 4px;
            }
        }
        button {
            &.swapper-btn {
                all: unset;
                font-weight: 700;
                display: flex;
                column-gap: 4px;
                align-items: center;
                cursor: pointer;
            }
        }
    }
  }
  &__menu-icon {
    display: none;
  }
  &__logo {
    cursor: pointer;
    .mobile {
      display: none;
    }
  }
  &__link {
    margin-right: 24px;
    text-decoration: none;
    font-weight: 700;
    line-height: 24px;
    color: var(--color-neutral-white);
    &:visited {
      color: var(--color-neutral-white);
    }
  }
  .btn {
    &--secondary {
      cursor: pointer;
      border: 1px solid var(--color-neutral-white);
      color: var(--color-neutral-white);
      background-color: transparent;
      margin-bottom: 0;
      padding: 11px 20px;
      border-radius: 8px;
      img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
      span {
        font-weight: 400;
        line-height: 18px;
        display: inline-block;
        vertical-align: middle;
        .show-balance { 
          text-decoration: underline; 
        }
        strong {
          font-weight: 700;
          line-height: 24px;
        }
        em {
          margin: 0px 10px;
        }
      }
    }
  }
  &__nav {
    text-align: right;
  }
  &__footer {
    padding: calc( 40px - 16px) 20px;
    background-image: url("../assets/icons/background-footer.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    &__nav-wrapper {
      align-items: center;
      --f-block-default-padding: 0px;
    }
    &__nav {
      display: flex;
      justify-content: flex-end;
      &__links {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-neutral-white);
        padding: 16px;
        gap: 32px;
        flex-wrap: wrap;
        a{
          cursor: pointer;
          color: var(--color-neutral-black);
          text-decoration: none;
          font-weight: 400;
          line-height: 24px;
        }
      }
      &__social {
        display: flex;
        justify-content: space-between;
        background-color: var(--color-neutral-white);
        padding: 16px;
        gap: 8px;
        img { cursor: pointer };
      }
    }
  }
  &__resilient{
      background-image: url("../assets/background-footer-resilient.png");
  }
  &__logo-wrapper {
    &__container {
      background-color: var(--color-neutral-white);
      padding: 16px;
      width: max-content;
      img {
        &.default-layout__logo{
            width: 107px;
            height: 37px;
        }
      }
    }
  }
  &__menu {
    display: none;
  }
  @include respond-to("medium and down") {
    &__header-box {
      box-shadow: none;
    }
    &__logo {
      display: block;
      text-align: center;
      &-header {
        .desktop {
          display: none;
        }
        .mobile {
          display: block;
        }
      }
    }
    &__logo-wrapper {
      order: 1;
    }
    &__menu-icon {
      display: inline-block;
    }
    &__links {
      &--header {
        display: none;
      }
      &--footer::v-deep {
        margin-left: 0;
        display: block;
        .navigation-link {
          display: block;
          margin-right: 0;
          margin-bottom: 25px;
        }
      }
      &--menu::v-deep {
        margin-left: 0;
        display: block;
        text-align: left;
        .navigation-link {
          display: block;
          margin-right: 0;
          margin-bottom: 26px;
        }
      }
    }
    .my-eprops {
      display: none;
    }
    .btn {
      &--secondary {
        display: none;
      }
    }
    &__nav {
      text-align: center;
    }
    &__footer {
      padding: calc(42px - 16px) calc(28px - 16px);
      gap: 10px;
      background-image: url("../assets/icons/background-footer-mobile.svg");
      &__nav-wrapper {
        --f-block-default-padding: 16px;
      }
      &__nav {
        flex-direction: column;
        &__links {
          justify-content: space-around;
          gap: 0px;
        }
        &__social {
          width: max-content;
          margin: 0 auto;
        }
      }
    }
    &__logo-wrapper {
      &__container {
        margin: 0 auto;
      }
    }
    &__menu {
      display: block;
      position: fixed;
      padding: 16px;
      width: 50%;
      height: calc(100vh - 57px);
      top: 74px;
      left: 50%;
      background-color: var(--color-neutral-black);
      z-index: 1;
    }
    &__wallet-wrapper {
      display: none;
    }
    .header-right {
        display: none;
    }
  }
}

.modal {
    &__content {
        top: -320%;
        width: 427px;
        padding:24px;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3 { 
                margin: 0px;
                line-height: 33px; 
            }
            img {
                cursor: pointer;
            }
        }
        &__form {
            display: flex;
            flex-direction: column;
            gap: 12px;
            &__title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__left {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
                &__right {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    .no__auth {
                        color: var(--color-zerosum-secondary-01);
                        cursor: pointer;
                    }
                }
                h2 {
                    margin: 0px;
                    line-height: 38px;
                }
                span {
                    color: var(--color-neutral-02);
                    line-height: 24px;
                }
                strong {
                    line-height: 24px;
                }
            }
            input {
                padding: 5.5px 16px;
                margin: 0px;
                font-size: 24px;
                line-height: 33px;
                font-weight: 700;
                border-radius: 4px;
                border: 1px solid #000000;
                &.form__error {
                    border: 1px solid var(--color-starter-red-02);
                }
            }
            span {
                gap: 0px;
                font-size: 12px;
                &.form__error__message {
                    color: var(--color-starter-red-02);
                }
            }
        }
        &__invert {
            display: flex;
            justify-content: center;
            img {
                cursor: pointer;
            }
        }
    }
    button {
        margin: 0px;
        width: 100%;
        padding: 11px 0px;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;
        text-transform: uppercase;
        border-radius: 8px;
        &:disabled {
            cursor: default;
            background: var(--color-neutral-03);
        }
        &.loading {
            background: transparent;
            border: 1px solid var(--color-neutral-03);
            padding: 11px 20px;
            display: flex;
            justify-content: center;
        }
    }
}
</style>
