import Vue from 'vue';
import Router from 'vue-router'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
const currenProjectId = process.env.VUE_APP_CURRENT_PROJECT_ID;
Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      component: () => import('./layouts/DefaultLayout'),
      children: [
        {
          path: '',
          name: 'home',
          redirect: { name:'project', params: { projectId: currenProjectId} },
          component: () => import('./views/Home')
        },
        {
          path: '/about-emprops',
          name: 'about-emprops',
          component: () => import('./views/AboutEmprops')
        },
        {
          path: '/learn',
          name: 'learn',
          component: () => import('./views/Learn')
        },
        {
          path: 'projects/:projectId/gallery/',
          name: 'gallery',
          component: () => import('./views/Gallery')
        },
        {
          path: 'projects/:projectId/art/:artId',
          name: 'nft-details',
          component: () => import('./views/NftDetails')
        },
        {
            path: 'projects/:projectId/studies/:studyId',
            name: 'study-details',
            component: () => import('./views/StudyDetails')
        },
        {
            path: 'projects/:projectId/test/:testId',
            name: 'test-mint-details',
            component: () => import('./views/TestMintDetails')
        },
        {
            path: 'projects/:projectId/art/:artId/traits/:attribute/:attributeValue',
            name: 'filtered-traits',
            component: () => import('./views/FilteredTraits')
        },
        {
            path: '/myemprops/:projectId',
            name: 'myemprops',
            component: () => import('./views/MyCollection')
        },
        {
          path: '/myemprops',
          redirect: { name:'myemprops', params: { projectId: currenProjectId } },
        },
        {
          path: '/project/:projectId',
          name: 'project',
          component: () => import('./views/ProjectDescription')
        },
        {
          path: '/projects',
          name: 'projects',
          component: () => import('./views/Projects')
        },
        {
          path: 'projects/:projectId/art/:artId/live',
          name: 'live',
          component: () => import('./views/Live')
        },
        {
          path: '/faq',
          name: 'faq',
          component: () => import('./views/Faq')
        },
        {
          path: '/big-bang-next-steps',
          name: 'big-bang-next-steps',
          component: () => import('./views/BigBangNextSteps')               
        },
        {
            path: '/blog',
            name: 'blog',
            component: () => import('./views/Blog')
        },
        {
            path: 'blog/:postId',
            name: 'post-detail',
            component: () => import('./views/PostDetail')
        },
        {
          path: '*',
          name: 'not-found',
          component: () => import('./views/NotFound.vue')
        },
      ]
    }
  ]
});

export default router;
