<template>
  <ul class="navigation-links">
    <li class="navigation-link">
        <div class="dropdown">
            <div class="dropdown-title" @click="openCloseDropdown(true)">
                <span>Projects</span>
                <img src="../assets/icons/chevron-down.svg" alt="chevron icon">
            </div>
            <div v-show="isOpen" class="dropdown-content-desktop">
                <div class="overlay" @click="openCloseDropdown(false)"></div>
                <span v-for="project in projects"
                    :key="project.id"
                    class="link"
                    @click="goTo('project', project.id)"
                >
                    {{ project.title }}
                </span>
            </div>
            <div v-show="isOpen" class="dropdown-content-mobile">
                <div class="overlay" @click="openCloseDropdown(false)"></div>
                <span v-for="project in projects"
                :key="project.id"
                class="link"
                @click="goTo('project', project.id)">
                    {{ project.title }}
                </span>
            </div>
        </div>
    </li>
    <li class="navigation-link mobile" >
        <span @click="goTo('about-emprops')">About EmProps</span>
    </li>
    <li class="navigation-link mobile">
        <span @click="goTo('faq')">FAQ</span>
    </li>
  </ul>
</template>

<script>
import api from '@/service/api';
const currenProjectId = process.env.VUE_APP_CURRENT_PROJECT_ID;

export default {
  data () {
    return {
      isOpen: false,
      projects: []
    }
  },
  async mounted() {
    this.projects = await api.projects.getAll();
  },
  methods: {
    async goTo(route, projectId) {
      if(route === 'project') {
          await this.$router.push({name: 'project', params: { projectId }});
          window.location.reload();
      } else if (route === 'gallery') {
        await this.$router.push({name: 'gallery', params: { projectId: currenProjectId }});
      }
      await this.$router.push({name: route});
      this.openCloseDropdown(false);
      this.$emit('close');
    },
    openCloseDropdown(value) {
      this.isOpen = value;
    }
  },
}
</script>

<style lang="scss" scoped>
.navigation-links {
  display: inline-block;
  margin-left: 32px;
  margin-bottom: 0;
  .navigation-link {
    display: inline-block;
    margin-right: 32px;
    margin-bottom: 0;
    .dropdown {
      display: flex;
      position: relative;
      align-items: center;
      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
      .dropdown-title {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        img {
          cursor: pointer;
          position: static; 
          flex: none;
          order: 1;
          flex-grow: 0;
        }
      }
      .dropdown-content-mobile {
        display: none;
      }
      .dropdown-content-desktop {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 240px;
        top: 34px;
        right: 0px;
        background-color: var(--color-neutral-black);
        span {
          z-index: 2;
          margin: 0;
          padding: 8px;
          color: var(--color-neutral-white);
          cursor: pointer;
          &:hover {
            background-color: var(--color-emerald);
          }
        }
      }
    }
    span {
      cursor: pointer;
      text-decoration: none;
      font-weight: 700;
      line-height: 24px;
      &.title {
        cursor: default;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &.mobile {
        display: none;
    }
  }
  @include respond-to("medium and down") {
    .navigation-link {
      margin-bottom: 6px !important;
      &.desktop {
          display: none;
      }
      &.mobile {
        display: block;
      }
      .dropdown {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .overlay {
          z-index: -1;
        }
        .dropdown-content-desktop {
          display: none;
        }
        .dropdown-content-mobile {
            display: block;
          .link {
            z-index: 1000;
            display: block;
            color: var(--color-neutral-02);
          }
          .link:last-child {
            padding-bottom: 0px;
          }
        }
      }
    }
  }
}
</style>
